<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx">
      <v-card-title class="primary--text">
        Edit Category
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-sheet class="mb-4" max-width="480" color="transparent">
          <v-skeleton-loader type="text" v-if="loading"></v-skeleton-loader>
          <v-text-field
            v-else
            flat
            outlined
            label="Category Name"
            v-model="name"
            :rules="[rules.required]"
            :error-messages="errorMessageName"
          ></v-text-field>
        </v-sheet>
        <v-sheet class="mb-4" max-width="480" color="transparent">
          <v-skeleton-loader
            type="paragraph"
            v-if="loading"
          ></v-skeleton-loader>
          <v-textarea
            v-else
            flat
            outlined
            label="Description"
            v-model="description"
            :rules="[rules.required]"
            :error-messages="errorMessageDes"
          ></v-textarea>
        </v-sheet>

        <h3 class="primary--text large font-weight-medium mb-3 mt-5">
          Modules & Permissions
        </h3>
        <v-row class="pb-4" v-if="dataLoading">
          <v-col cols="12" md="6">
            <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title v-if="!loading">
        <v-spacer></v-spacer>
        <v-btn color="secondary" depressed @click="goBack" text class="mx-3">
          cancel
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="editCategory()"
          :loading="editLoading"
        >
          <v-icon left> mdi-content-save </v-icon> Save
        </v-btn>
      </v-card-title>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { getCategory, editCategory } from "../../services/categoriesService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    loading: false,
    dataLoading: false,
    editLoading: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    getCategoryReturn: null,
    editCategoryReturn: null,
    categoryPermissions: [],
    modules: [],
    category: {
      name: "",
      description: "",
    },
  }),
  async created() {

      this.loading = true;
      this.getCategoryReturn = await getCategory(this.$route.params.uuid);
      this.category = this.getCategoryReturn;
      this.name = this.category.name;
      this.description = this.category.description;
      this.category.category_permissions.forEach((permission) => {
        this.categoryPermissions.push(permission.permission_id);
      });
      this.loading = false;
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "PageViewCategory",
        params: { uuid: this.category.uuid },
        query: { bc: this.category.name },
      });
    },
    updatePermissions(permissions) {
      permissions.forEach((permission) => {
        if (!permission.value) {
          this.categoryPermissions = this.categoryPermissions.filter(
            (element) => element !== permission.id
          );
        } else {
          if (!this.categoryPermissions.includes(permission.id))
            this.categoryPermissions.push(permission.id);
        }
      });
    },
    async editCategory() {
      if (this.name.trim() == "") {
        this.errorMessageName = "Required.";
      }

      if (this.description.trim() == "") {
        this.errorMessageDes = "Required.";
      }

      if (this.name && this.description) {
        let xper = [];
        this.categoryPermissions.forEach((permission) => {
          xper.push({ permission_id: permission });
        });
        let data = {
          uuid: this.category.uuid,
          name: this.name,
          description: this.description,
          permission_ids: this.categoryPermissions,
          category_permissions: xper,
        };
        this.editLoading = true;
        this.editCategoryReturn = await editCategory(data);
        this.editLoading = false;
        if (this.editCategoryReturn == "success") {
          this.goBack();
        }
      }
    },
  },
};
</script>